.jobdetail-form {
    .dropdown-style {
        div {
            width: 100%;
        }
    }
}

.jobdetail-form {
    .ownership-dropdown-style {
        width: 100%;
        div {
            width: 100%;
        }
    }
}