#pannella-content {
    background-color: #FAFAFA;
}

.fencedetail-form {
    .dropdown-style {
        div {
            width: 100%;
        }
    }
}